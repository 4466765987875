import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Category, CategoryQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const USER_URL = `${API_URL}/category`
const CALL_API_URL = `${API_URL}/category`
const GET_CAT_URL = `${API_URL}/category/query`

const getCategory = (query: string): Promise<CategoryQueryResponse> => {
  return axios
    .get(`${API_URL}/categoryMaster/query?${query}`)
    .then((d: AxiosResponse<CategoryQueryResponse>) => {
      
      return d.data
    })
}

const deleteCategoryById = (id: any) => {
  return axios
    .post(`${API_URL}/categoryMaster/delete/${id}`, {}, getToken())

    .then((response: any) => response.data)
}

const createCategoryMaster = (data: any) => {
  return axios
    .post(`${API_URL}/categoryMaster/add`, data, getToken())
    .then((response: any) => response.data)
}

const updateCategoryMaster = (name: any, updatedBy: any, id: any) => {
  return axios
    .put(`${API_URL}/categoryMasterUpdates/${id}`, {name, updatedBy}, getToken())
    .then((response: any) => {
      
      return response.data
    })
}
const deleteCategoryMaster = (name: any, updatedBy: any, id: any) => {
  return axios
    .post(`${API_URL}/categoryMaster/delete/${id}`, {}, getToken())
    .then((response: any) => {
      
      return response.data
    })
}
const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getCategory,
  deleteUser,
  createCategoryMaster,
  deleteSelectedUsers,
  deleteCategoryById,
  updateCategoryMaster,
  deleteCategoryMaster,
}
