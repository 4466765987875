import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import { NewsQueryResponse} from './_models'
import { getToken } from '../../../../auth' 

const API_URL = process.env.REACT_APP_API_URLS
const CLAIM_URL = `${API_URL}/claimform`
const REPORT_URL = `${API_URL}/report`
var AuthVerify = getToken.call(this)


// const getData = (query: string): Promise<NewsQueryResponse> => {
//   return axios
//     .get(`${API_URL}/listUsers/query?${query}`, getToken())
//     .then((response: AxiosResponse<NewsQueryResponse>) => {
//      
//       return response.data
//     })
// }

const getData = (query: string): Promise<NewsQueryResponse> => {
    return axios
      .get(`${API_URL}/listReport/query?${query}`, getToken())
      .then((response: AxiosResponse<NewsQueryResponse>) => {
        
        return response.data
      })
  }
  
  const reports=async(data:any)=>{
    
    const res = await axios.post(`${API_URL}/report`,data, getToken())
    
    return res
   }
   
const getPlan = async () => {
  const res = await axios.get(`${API_URL}/plan/getPlan`, getToken())
  // 
  return res
}

const getPlanStatus = async () => {
  const res = await axios.get(`${API_URL}/planStatus`, getToken())
  // 
  return res
}


 
export {
 getPlan,
 getPlanStatus,
 reports,
 getData,
}
