/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import {initialQueryState, KTSVG} from "../../../_metronic/helpers"
import { PageTitle } from '../../../_metronic/layout/core'
import { widgetCount} from '../core/_request'
import { StatisticsWidget5 } from './StatisticsWidget5'
import { PaymentReceiveGraph } from './PaymentReceiveGraph'
import {QueryRequestProvider}  from '../core/QueryRequestProvider'
import{QueryResponseProvider} from '../core/QueryResponseProvider'
import {ListViewProvider } from '../core/ListViewProvider'

const DashboardContent: FC = () => {
  const [count, setCount] = useState<any>([])
  
  useEffect(() => {
    widgetCount().then((res: any) => {
      setCount(res.data.data)
    })
  }, [])

  const intl = useIntl()

 

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#F1416C'
            iconColor='white'
            title={count?.totalUsers}
            description='Total Users'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#e38e12'
            iconColor='white'
            title={count?.totalBuyers}
            description='Total Buyers'
            img={toAbsoluteUrl('/media/patterns/vector-3.png')}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#3bbaf3ed'
            iconColor='white'
            title={count?.totalSeller}
            description='Total Sellers'
            img={toAbsoluteUrl('/media/patterns/vector-blue.png')}
          />
        </div>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#df73f1'
            iconColor='white'
            title={count?.totalTraders}
            description='Total Traders'
            img={toAbsoluteUrl('/media/patterns/vector-4.png')}
          />
        </div>
      </div>

      <div className='row gy-5 gx-xl-8'>
        <div className='col-12'>
          <PaymentReceiveGraph className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
    </>
  )
}

export { DashboardContent }

const DashboardWrapper  = () => {
 return(
  <QueryRequestProvider>
  <QueryResponseProvider>
    <ListViewProvider>
      <DashboardContent />
    </ListViewProvider>
  </QueryResponseProvider>
</QueryRequestProvider>
 );
}

export {DashboardWrapper }