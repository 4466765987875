import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import DatePicker from 'react-datepicker'
import { getListBuySellProduct, getSellLocationlist } from '../../core/_requests'


const EmployeeListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()
  const [dateRange, setDateRange] = useState([null, null])

  const [product, setProduct] = useState<any>([])
  const [productId, setProductId] = useState<string | undefined>()

  const [location, setLocation] = useState<any>([])
  const [locationId, setLocationId] = useState<string | undefined>()
  

  const [startDate, endDate] = dateRange
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const filterData = () =>{
    var date = (document.getElementById('Daterange') as HTMLInputElement).value
    updateState({
      filter: { productId,locationId,date},
      ...initialQueryState,
    })
    // 
  }

  const resetData =()=>{
    updateState({
      filter: {filter: undefined, ...initialQueryState},
        ...initialQueryState,
    })
   }
  useEffect(() => {
    
    getListBuySellProduct().then((res:any)=>{
      setProduct(res.data.data)
    })

    getSellLocationlist().then((res:any)=>{
      
      setLocation(res.data.data)
    })
  }, [])

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Product :</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setProductId(e.target.value)}
              value={productId}
            >
              <option value=''>All</option>
              {product?.map((item: any, i: any) => {
                return (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Location :</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setLocationId(e.target.value)}
              value={locationId}
            >
              <option value=''>All</option>
              {location?.map((item: any, i: any) => {
                return (
                  <option key={i} value={item.area}>
                    {item.pincode.region}
                  </option>
                )
              })}
            </select>
          </div>
           {/* begin::datefilter */}
           <div className='mb-10'>
              <label className=' col-form-label required fw-bold fs-6'>Date Range</label>
              <DatePicker
                className='form-select form-select-solid fw-bolder '
                id='Daterange'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update)
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
                showYearDropdown
                showMonthDropdown
                dropdownMode='select'
              />
            </div>
             {/* begin::datefilter */}
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {EmployeeListFilter}






