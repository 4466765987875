import React, {useEffect, useRef, useState} from 'react'
import {
  getData,
  getPlan, getPlanStatus,reports
} from '../core/_requests'
import DatePicker from 'react-datepicker'
import Select, {Theme} from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import './report.css'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { stat } from 'fs/promises'
import { start } from '@popperjs/core'
import * as Yup from 'yup'
import {useFormik} from 'formik'


const roleDetailsSchema = Yup.object().shape({
  date: Yup.string().required('Please Choose Your Date'),
});

const ReportContent = () => {
  const tableRef = useRef(null)
  const [OCDate, setOCDate] = useState('')
  const [plan, setPlan] = useState<any>([])
  const [planid, setplanid] = useState('')
  const [statusid, setstatusid] = useState('')
  const [mobile, setmobile] = useState('')
  const [report, setReport] = useState<any>([])
  
  const [loading, setLoading] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [planstatus, setplanstatus] = useState<any>([])
  const {mode} = useThemeMode()
  const [alertMessage, setAlertMessage] = useState<string | null>(null)


  useEffect(() => {
    getPlan().then((res: any) => {
      const mappedOptions = res.data.Data.map((com: {id: any; name: any}) => ({
        value: com.id,
        label: com.name,
      }))
      
      const allOption = {value: 0, label: 'All'}
      const optionsWithAll = [allOption, ...mappedOptions]
      setPlan(optionsWithAll)
    })

    getPlanStatus().then((res: any) => {
      const mappedOptions = res.data.Data.map((com: {id: any; name: any}) => ({
        value: com.id,
        label: com.name,
      }))
      const allOption = {value: "", label: 'All'}
      const optionsWithAll = [allOption, ...mappedOptions]
      setplanstatus(optionsWithAll)
    })
    // getData().then((res.any))=>{

    // }
  }, [])

  
  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Client_Report_${fileName}`,
    sheet: 'Users',
  })

  //dropdown
  if (mode === 'dark') {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#393944',
        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
        '&:hover': {
          color: 'red',
        },
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-input-solid-color)',
      }),
    }
  } else {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#c5c5c5',

        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: '#c5c5c5',
      }),
    }
  }

  
  const [selectedOption, setSelectedOption] = useState(null)
  const [planselectedOption, setplanselectedOption] = useState(null)
  

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption)
    var text = selectedOption?.label
    var val = selectedOption?.value
    setplanid(val)
  }

  const planhandleSelectChange=(planselectedOption: any)=>
  {
    setplanselectedOption(planselectedOption)
    var val = planselectedOption?.value
    
    if(val === 0 ){
      var statusVal = typeof String (val);
      setstatusid(statusVal);
      
    }else{
      setstatusid(val);
      
    }
  }

   
  const companyReport = () => {
    
      let date = (document.getElementById('Daterange') as HTMLInputElement).value;
      if (!date) {
        setAlertMessage('Please select a date range.')
        setLoading(false) 
        return 
      }else{
           setAlertMessage('')
      const data = {
        planId: planid,
        statusId: statusid,
        date: date,
        mobile: mobile,
      };
      
      reports(data).then((res: any) => {
        setReport(res.data.Data);
        setbtnClearDisable(false);
      });
      }
  };
  
  
  const clearReport = () => {
    setSelectedOption(null)
    setplanselectedOption(null)
    setReport([])
    setbtnClearDisable(true)
  }

  const handlemobile=(e:any)=>{
    setmobile(e.target.value)
  }


  const formik = useFormik({
    initialValues: {
      date: '',
    },
    validationSchema: roleDetailsSchema,
    onSubmit: (values) => {
      // Handle form submission if needed
    },
    });


  return (
    <>
      <div className='card '>
      {/* <form onSubmit={formik.handleSubmit} noValidate className='form'> */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
            <div className='col-lg-3'>
              <label className=' col-form-label  fw-bold fs-6'>Filter By Plan</label>
              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={selectedOption}
                onChange={handleSelectChange}
                options={plan}
                isSearchable
                // onInputChange={handleInputChange}
                styles={customStyles}
              />
            </div>
            <div className='col-lg-3'>
              <label className=' col-form-label    fw-bold fs-6'>Filter By Plan Status</label>       
              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={planselectedOption}
                onChange={planhandleSelectChange}
                options={planstatus}
                isSearchable
                // onInputChange={handleInputChange}
                styles={customStyles}
              />
            </div>
            <div className='col-lg-3'>
            <label className=' col-form-label  fw-bold fs-6'>Mobile Number</label>
                <input type="text"  placeholder="Search Mobile" onChange={(e)=>{handlemobile(e)}} className='form-control form-control-lg form-control-solid' />
              </div>

            <div className='col-lg-3'>
              <label className=' col-form-label required fw-bold fs-6'>Filter By Date Range</label>
              <DatePicker
                className='form-select form-select-solid fw-bolder '
                id='Daterange'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update)
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
                showYearDropdown
                showMonthDropdown
                dropdownMode='select'
              />
              {alertMessage && <div className='text-danger'>{alertMessage}</div>}
            </div>
            
            <div className='col-lg-12 row' style={{float: 'right', paddingRight: '0px'}}>
              <div className='col-lg-8'> </div>
              <div className='col-lg-2 pt-12'>
                <button onClick={companyReport} className='btn btn-primary col-lg-12'  type='submit'>
                  Search
                </button>
              </div>
              <div className='col-lg-2 pt-12'>
                <button
                  onClick={clearReport}
                  className='btn btn-info col-lg-12'
                  disabled={btnClearDisable}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>

          <div className='table-responsive'>
            {/* begin::Table */}
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              ref={tableRef}
            >
              {/* begin::Table head */}
              <thead>  
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px'> UID</th>
                  <th className='min-w-140px'> USER NAME</th>
                  <th className='min-w-140px'> REGISTERED DATE</th>
                  <th className='min-w-140px'> MOBILE </th>
                  <th className='min-w-120px'> PLAN</th>
                  <th className='min-w-100px'>PLAN STATUS </th>
                  <th className='min-w-100px'> PLAN START </th>
                  <th className='min-w-100px'> PLAN END</th>
                </tr>
              </thead>
              {loading && (
                <tbody>
                  <tr>
                    <td colSpan={14} style={{textAlign: 'center'}}>
                      {' '}
                      Please wait...{' '}
                    </td>
                  </tr>
                </tbody>
              )}
              {!loading && (
                <tbody>
                   {report.length > 0 ? (
                  report?.map((item: any, i: any) => {
                    return (
                      <tr key={i}>
                        <td>{item?.id}</td>
                        <td>
                          {item?.name}
                        </td>
                        
                        <td>
                          {new Date(item.created).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })}
                        </td>
                        <td> {item?.mobile} </td>
                        <td> {item?.plan?.name} </td>
                        <td > <span className={`status-${item?.status}`}>{item?.plan_status?.plan_status} </span></td>
                        <td>
                          {new Date(item.plan_start).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })}
                        </td>
                        <td>
                          {new Date(item.plan_end).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })}
                        </td>
                      </tr>
                    )
                  })):(
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center '>
                          <h6 className='text-muted fw-bold'>  No matching records found </h6>
                        </div>
                      </td>
                    </tr>
                  ) }  
                </tbody>
              )}
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        {/* </form> */}
      </div>
    </>
  )
}

export {ReportContent}
