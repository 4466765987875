/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useParams, useLocation, useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'

import Avatar from 'react-avatar'
import {dateFormat, useAuth} from '../../../auth'
import {viewData} from '../user-list/core/_requests'

export function ViewUser() {
  const location = useLocation()
  const {id} = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  useEffect(() => {
    viewData(id).then((res: any) => {
      console.log("rrr",res.data[0]);
      
      setData(res.data[0])
    })
  }, [])
// Helper function to format dates
const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString()
}
  return (
    <>
      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>User Details</h3>
              </div>
            </div>

            <div className='card-body'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>User ID</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.id}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>User Name</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.name}</span>
                </div>
              </div>
              <div className='row'>
                <label className='col-lg-4 fw-bold text-muted'>Mobile Number</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{data?.mobile}</span>
                </div>
              </div>
              
            </div>
            <div className='card-body'>
              <h4>Plans Details</h4>
            <div className='table-responsive'>

                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-340px'> Plan</th>
                      <th className='min-w-340px'> Amount</th>
                      <th className='min-w-340px'> Days</th>
                      <th className='min-w-100px'>Category</th>
                      <th className='min-w-100px'>Start Date</th>
                      <th className='min-w-100px'>End Date</th>
                      <th className='min-w-100px'>Expired</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data?.user_plan_details?.map((plan: any, index: number) => (
                      <tr key={index}>
                        <td>{plan.planName}</td>
                        <td>{plan.amount}</td>
                        <td>{plan.days}</td>
                        <td>{plan.planCategoryName}</td>
                        <td>{formatDate(plan.startDate)}</td>
                        <td>{formatDate(plan.endDate)}</td>
                        <td>{plan.isExpired ? 'Yes' : 'No'}</td>
                      </tr>
                    ))}
                  </tbody>
                  {/* end::Table body */}
                </table>
                
                {/* end::Table */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
