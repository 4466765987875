import React, {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getCity, getCountry, getState, updateMarket} from '../market-list/core/_requests'
import {Market} from '../market-list/core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'

const imageUrl = process.env.REACT_APP_IMAGE_UPLOADS
const clinicDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Employee name is required'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  location: Yup.string().required('Location is required'),
})

type Props = {
  initialValues: Market
}
export const EditEmployee: FC<Props> = ({initialValues}) => {
  const [data, setData] = useState<Market>(initialValues)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const [role, setRole] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [formImage, setFormImage] = useState<File | null>(null)
  // Get role using type
  const [country, setCountry] = useState<any>([])
  const [city, setCity] = useState<any>([])
  const [state, setState] = useState<any>([])
  //Image Target
  interface Event<T = EventTarget> {
    target: T
    // ...
  }

  //get type
  const {currentUser} = useAuth()

  const type = currentUser?.type
  //end get type
  const formik = useFormik<Market>({
    initialValues,
    validationSchema: clinicDetailsSchema,
    onSubmit: (values, actions) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        updatedData.updatedBy = currentUser?.userId
        //Image and data Append to Form Data

        updateMarket(data, id).then((res) => {
          showAlert(res?.data?.Message, res?.data?.Code)
          navigate('/market', {replace: true})
        })
        setLoading(false)
      }, 1000)
    },
  })

  useEffect(() => {
    getCountry().then((res: any) => {
      setCountry(res.data.data)
    })
    getState(data?.country).then((res: any) => {
      
      setState(res.data.data)
    })
    getCity(data?.state).then((res: any) => {
      setCity(res.data.data)
    })
  }, [])

  const handleChange = (e: any) => {
    
    formik.setFieldValue('country', e.target.value)
    getState(e.target.value).then((res: any) => {
      
      setState(res.data.data)
    })
  }
  //
  const handleStateChange = (e: any) => {
    
    formik.setFieldValue('state', e.target.value)
    getCity(e.target.value).then((res: any) => {
      setCity(res.data.data)
    })
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Market </h3>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        {/** Company Info */}
        <div className='card-body border-top p-9'>
          {/** Company Info */}

          <div className='row mb-6'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Market Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Employee Name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Country</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Role'
                  {...formik.getFieldProps('country')}
                  onChange={(e) => handleChange(e)}
                >
                  <option value=''>Select a Country</option>
                  {country.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.country && formik.errors.country && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.country}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>State</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Role'
                  {...formik.getFieldProps('state')}
                  onChange={(e) => handleStateChange(e)}
                >
                  <option value=''>Select a State</option>
                  {state.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.state && formik.errors.state && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.state}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>City</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Role'
                  {...formik.getFieldProps('city')}
                >
                  <option value=''>Select a City</option>
                  {city.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.city && formik.errors.city && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.city}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Location</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Market Location'
                  {...formik.getFieldProps('location')}
                />
                {formik.touched.location && formik.errors.location && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.location}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default EditEmployee
