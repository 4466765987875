/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {deleteUserTypeById, updateUserType} from '../../core/_requests'
import {UserType} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import {MyContext} from '../../ContextFile'
import React from 'react'
import {useAuth} from '../../../../../auth'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'

// import {getExCRUD} from '../../../../category-access/category-setting-list/core/_requests'
type Props = {
  category: any
}
interface Event<T = EventTarget> {
  target: T
  // ...
}
const UserTypeActionsCell: FC<Props> = ({category}) => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [showEModal, setEShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(category?.name)
  const [ErrorName, setErrorName] = useState('')
  const [ErrorEmp, setErrorEmp] = useState('')

  const [formImage, setFormImage] = useState<File | null>(null)
  const [Image, setImage] = useState<any>(category?.icon)
  const handleEClose = () => {
    setEShow(false)
    navigate('/user-type')
  }
  const deleteItem = () =>
    deleteUserTypeById(category.primaryId).then((res) => {
      setShow(false)
      showAlert(res?.Message, res?.Code)
      navigate('/user-type')
    })
  const {state, setState} = React.useContext(MyContext)
  const {currentUser} = useAuth()
  const [categoryaccess, setcategoryaccess] = useState<any>([])
  const {refetch} = useQueryResponse()
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 6).then((res: any) => {
      var crudres = res.data?.Data[0]
      setcategoryaccess(crudres)
    })
  }, [])

  const handleShow = () => {
    if (categoryaccess?.remove === 0) {
      toast.error('You do not have permission to access this page', {
        position: 'top-right',
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px',
        },
        closeButton: false,
      })
    } else {
      setShow(true)
    }
  }
  const imageHandler = (e: Event<HTMLInputElement>) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result)
      }
    }
    const files = e.target.files
    if (files) {
      setFormImage(files[0])
      setErrorEmp('')
      reader.readAsDataURL(files[0])
    }
  }

  const submitData = () => {
    if (name != '') {
      setLoading(true)
      const formData = new FormData()
      //image append to formData
      if (formImage) formData.append('image', formImage)
      formData.append('name', name)
      updateUserType(formData, category.primaryId).then((res: any) => {
        

        navigate('/user-type', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.Message, res?.Code)
      })
    } else {
      if (name === '') {
        setErrorName('Name is required')
      }
    }
  }
  return (
    <>
      <ToastContainer />
      <div className='d-flex justify-content-end flex-shrink-0'>
        {categoryaccess?.edit === 1 && (
          <a
            onClick={() => {
              
              setEShow(true)
            }}
            href='#'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </a>
        )}

        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>
      <Modal show={showEModal} onHide={handleEClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mb-6'>
            <div className='row'>
              <div className='col-lg-12 fv-row'>
                <label className='col-form-label required fw-bold fs-6'> Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Category  Name'
                  id='Name'
                  value={name}
                  onChange={(e: any) => {
                    setName(e.target.value)
                    setErrorName('')
                  }}
                />
                {ErrorName ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{ErrorName}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='col-lg-12 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Image</label>
                <input
                  type='file'
                  name='avatar'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={imageHandler}
                  accept='.png, .jpg, .jpeg'
                />
                <img src={Image} style={{width: '100px', height: '100px'}} />
                {ErrorEmp ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{ErrorEmp}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleEClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={submitData}>
            {!loading ? 'Submit' : 'Loading...'}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{category.name}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export {UserTypeActionsCell}
