// @ts-nocheck
import {Column} from 'react-table'
import {EmployeeInfoCell} from './EmployeeInfoCell'
import {EmployeeTwoStepsCell} from './EmployeeTwoStepsCell'
import {EmployeeActionsCell} from './EmployeeActionsCell'
import {EmployeeCustomHeader} from './EmployeeCustomHeader'
import {Employee} from '../../core/_models'

const EmployeeColumns: ReadonlyArray<Column<Employee>> = [
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'register.name',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Product' className='min-w-125px' />
    ),
    accessor: 'ProductMaster.name',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Category' className='min-w-125px' />
    ),
    accessor: 'buysell_category.name',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Location' className='min-w-125px' />
    ),
    accessor: 'pincode.region',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status',
    Cell: ({...props}) => <EmployeeInfoCell sell={props.data[props.row.index]}  columnAccessor ="status"/>,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Start Date' className='min-w-125px' />
    ),
    accessor: 'created_date',
    Cell: ({...props}) => <EmployeeInfoCell sell={props.data[props.row.index]}   columnAccessor ="Start Date"/>,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='End Date' className='min-w-125px' />
    ),
    accessor: 'valid_date',
    Cell: ({...props}) => <EmployeeInfoCell sell={props.data[props.row.index]} columnAccessor ="End Date"/>,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <EmployeeActionsCell sell={props.data[props.row.index]} />,
  },
]

export {EmployeeColumns}
