/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Product} from '../../core/_models'
import {dateFormat} from '../../../../../../auth'

type Props = {
  product: any
}

const ProductStartInfoCell: FC<Props> = ({product}) => {
  return <div className='symbol-label'>{dateFormat(product?.register?.plan_start)}</div>
}
const ProductEndInfoCell: FC<Props> = ({product}) => {
  return <div className='symbol-label'>{dateFormat(product?.register?.plan_end)}</div>
}
export {ProductStartInfoCell, ProductEndInfoCell}
